import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted,onActivated, getCurrentInstance,nextTick} from 'vue';
import ClientRepairManualUtil ,{IClientRepairManualDataObj} from './clientRepairManualUtil';
export default defineComponent ({
    name: 'ClientRepairManual',
    beforeRouteEnter(to,from,next){
        if(sessionStorage.getItem('userInfo')){
            next();
        }else{
            next('/clientLogin');
        }
        next((curProxyInst:any)=>{
            document.title = '车辆维修手册';
        })
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IClientRepairManualDataObj=reactive<IClientRepairManualDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            listData:[],
            listDataBak:[],
            otherParams:{
                queryParam:{
                    filterKey:'',
                    brandId:''
                },
                toolBrandData:[]
            }
        })
        onActivated(()=>{})
        onBeforeMount(()=>{
            dataObj.utilInst=new ClientRepairManualUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.clientQueryManualCx();
                await dataObj.utilInst.queryManualByType();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'brandId'==params.comboId){
                    return dataObj.otherParams.toolBrandData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:string,selectId:string)=>{
            if('brandId'==selectId){
                dataObj.utilInst.filerManual();
            }else if('cx'==selectId){
                if(newValue)await dataObj.utilInst.viewManual(newValue);
            }
        }

        const searchHandler=()=>{
            dataObj.utilInst.filerManual();
        }
        const personInfoHandler=async ()=>{
            await proxy.$router.replace({path:'/clientPersonCenter'});
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,searchHandler,personInfoHandler
        }
    }
});